import React from "react"
import Layout from "../components/global/layout"
import ButtonLink from "../components/form/ButtonLink"
import LottieAnimation from "../components/block/AnimationLottie"
import WhiteLogo from "../images/brand/logo-white.svg"
import BrookCanestan from "../images/brand/brook-canestan.svg"

import styled from "@emotion/styled"
import { urls } from "../data/urls"

const Container = styled.div`
  height: 100%;
  padding: 32px 24px;
  display: flex;
  flex-flow: column nowrap;
`

const Logos = styled.img`
  display: block;
  opacity: 0.35;
  width: 47.4%;
  margin-top: auto;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0.7rem;
`

const Paragraph = styled.p`
  text-align: center;
  margin-bottom: 1rem;
`

function IndexPage(props) {
  return (
    <Layout logoOverride={WhiteLogo} hideHamburger={true} marginless>
      <LottieAnimation />
      <Container>
        <Logos src={BrookCanestan} alt="Brook & Canestan" />
        <Paragraph>Developed in partnership with Canesten</Paragraph>
        <ButtonLink
          to={urls.TERMS}
          buttonStyle={"inverted"}
          backgroundColor={props => props.theme.apricot}
        >
          Begin
        </ButtonLink>
      </Container>
    </Layout>
  )
}

export default IndexPage
