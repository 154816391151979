import React from "react"
import Lottie from "react-lottie"
import styled from "@emotion/styled"
import animationData from "../../utils/lottie.json"

const Container = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  padding-bottom: 100px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  z-index: -1;
  animation: colorChange 6s infinite linear;
  @keyframes colorChange {
    0% {
      background: ${props => props.theme.vanilla};
    }
    25% {
      background: ${props => props.theme.peach};
    }
    50% {
      background: ${props => props.theme.rose};
    }
    75% {
      background: ${props => props.theme.apricot};
    }
    100% {
      background: ${props => props.theme.vanilla};
    }
  }
`

export default function AnimationLottie() {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    viewBoxSize: {
      width: "200px",
      height: "400px",
    },
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  }
  return (
    <Container>
      <Lottie options={defaultOptions} height={"100%"} width={"100%"} />
    </Container>
  )
}
